import shortid from 'shortid';
import i18next from 'i18next';
import moment from 'moment';
import { get } from 'lodash';

import { isArrayWithItems, groupBy } from './arrayHelper';
import createMongoObjectId from './createMongoObjID';
import update from '../redux/update';
import { findTileElement, getFileExtension, getFileName, getThumbFileName } from './fileManagement';
import { isValueExists } from './valueHelper';

import { atomicInputElementPath } from '../components/protocol/production-stand/ProductionStandReducer';
import { groups } from '../components/protocol/template-builder/constants/groups';
import { getElementSettings, protocolTemplateOperations } from '../components/protocol/template-builder/constants/setup';
import { inputElements } from '../components/protocol/template-builder/constants/inputElements';
import { documentStatus } from '../constants/DocumentStatus';
import { allowedFileTypes, config, reactAppCustomer } from '../constants/Config';
import { revisionNumber } from '../constants/Revision';
import { operationAtomicEnum } from '../constants/OperationAtomicEnum';
import { certificationDocumentTypesTranslations } from '../constants/CertificationType';
import { APP_CUSTOMER } from './env';

import { siemensPDFLogo } from '../resources/images/SiemensPDFLogo';

import { sectionTypes } from '../components/protocol/production-stand-new/constants';

export const mapStringToBoolean = checked => {
    return checked === 'true' ? true : false;
};

export const mapBooleanToString = checked => {
    return checked === true ? 'true' : 'false';
};

export const getColumnsNumber = elements => {
    return Math.max.apply(
        Math,
        elements.map(o => o.column)
    );
};

export const getRowsNumber = elements => {
    return Math.max.apply(
        Math,
        elements.map(o => o.row)
    );
};

export const isProtocolDisabled = status => {
    return status === documentStatus.released || status === documentStatus.depreciated;
};

export const findProtocolValue = (array, language) => {
    if (isArrayWithItems(array)) {
        return array.find(x => x.language === language)?.value || '';
    }
    return '';
};

export const findProtocolFormattedValue = (array, language) => {
    if (isArrayWithItems(array)) {
        return array.find(x => x.language === language)?.formattedValue || '';
    }
    return '';
};

export const findExistingProtocolValue = (array, language) => {
    if (isArrayWithItems(array)) {
        return array.find(x => x.language === language)?.value || array.find(x => !!x.value)?.value || '';
    }
    return '';
};

export const findExistingProtocolFormattedValue = (array, language) => {
    if (isArrayWithItems(array)) {
        const value = array.find(x => x.language === language)?.formattedValue || array.find(x => !!x.value)?.formattedValue || '';
        return value;
    }
    return '';
};

export const setMultilanguageValue = (value, oldValue, language, path = 'value') => {
    const languageIndex = oldValue.findIndex(el => el.language === language);

    if (languageIndex !== -1) {
        return update.set(oldValue, `${languageIndex}.${path}`, value);
    } else {
        return [
            ...oldValue,
            {
                language: language,
                [path]: value,
            },
        ];
    }
};

export const mapValueModel = value => {
    if (isArrayWithItems(value)) {
        return value.map(item => {
            return {
                language: item.language,
                value: item.value,
                formattedValue: item.formattedValue,
            };
        });
    }

    if (value) {
        return value;
    }

    return [];
};

export const mapAttachmentModel = value => {
    if (isArrayWithItems(value)) {
        return value.map(item => {
            return {
                name: item.name,
                filename: item.filename,
                link: item.link,
            };
        });
    }

    return [];
};

export const mapTableArrayToObject = array => {
    let newObject = {};
    array.forEach((item, index) => {
        newObject = update.set(newObject, `${item.row}.${item.column}`, { ...item, index: index });
    });
    return newObject;
};

export const mapSectionParagraphToSaveModel = (section, tiles) => {
    if (!isArrayWithItems(section.value)) {
        return [];
    }

    const value = section.value.map(element => {
        if (!element.formattedValue) {
            return element;
        }

        const formattedValue = mapFormattedValue(element.formattedValue, tiles);

        return {
            ...element,
            formattedValue: JSON.stringify(formattedValue),
        };
    });

    return value;
};

export const mapFormattedValue = (value, tiles) => {
    if (!value) {
        return null;
    }

    let formattedValue = JSON.parse(value);

    formattedValue.ops = formattedValue.ops.map(atom => {
        const isImageInsert = get(atom, 'insert.image');

        if (isImageInsert) {
            const imageUrl = atom.insert.image;
            const mediaName = getFileName(imageUrl);
            const imageTile = tiles.some(x => getFileName(x.url) === mediaName);

            atom.insert.image = imageTile ? mediaName : imageUrl;
        }

        return atom;
    });

    return formattedValue;
};

export const mapProtocolSectionToSaveModel = (protocolSection, tiles) => {
    return {
        _id: protocolSection._id || undefined,
        sectionLayoutType: protocolSection.type || undefined,
        sectionHeading: (Object.values(groups).includes(protocolSection.type) && protocolSection.value) || undefined,
        sectionParagraph:
            (protocolSection.type === groups.sectionParagraph && mapSectionParagraphToSaveModel(protocolSection, tiles)) || undefined,
        documentationCharacteristic: protocolSection.documentationCharacteristic?.map(documentationCharacteristic => {
            return {
                _id: documentationCharacteristic._id,
                column: documentationCharacteristic.column?.toString() || undefined,
                row: documentationCharacteristic.row?.toString() || undefined,
                header: documentationCharacteristic.value || undefined,
                columnWidth: documentationCharacteristic.width || undefined,
                color: documentationCharacteristic.highlighted || undefined,
                documentationCharacteristicName: documentationCharacteristic.subtitle?.value || undefined,
                imageAlignment: documentationCharacteristic.alignment || undefined,
                autoFillValue: mapStringToBoolean(documentationCharacteristic.autoFillValue) || undefined,
                gtXlsExport: documentationCharacteristic.gtXlsExport
                    ? {
                          templateFile: documentationCharacteristic.gtXlsExport.templateFile || undefined,
                          worksheet: documentationCharacteristic.gtXlsExport.worksheet || undefined,
                          columnName: documentationCharacteristic.gtXlsExport.columnName || undefined,
                      }
                    : undefined,
                inputElement: documentationCharacteristic.inputElement?.map(input => {
                    const { _id, type, checked, relevantForValidation, dropdown, signedBy, autofillPerson, mediaInput, formattedValue } =
                        input;

                    return {
                        _id: _id,
                        inputElementType: type,
                        formattedValue: formattedValue ? JSON.stringify(mapFormattedValue(formattedValue, tiles)) : undefined,
                        checked: mapStringToBoolean(checked),
                        relevantForValidation: mapStringToBoolean(relevantForValidation),
                        dropDown: type === inputElements.dropdown ? dropdown : undefined,
                        signedBy:
                            type === inputElements.sign
                                ? {
                                      requestedSignatureRole: signedBy.requestedSignatureRole
                                          ? [signedBy.requestedSignatureRole]
                                          : undefined,
                                      signatureRequired: mapStringToBoolean(signedBy.signatureRequired),
                                      signedByID: signedBy.signedByID || undefined,
                                      signedAt: signedBy.signedAt || undefined,
                                      signatureImage: {
                                          mediaName: signedBy.signatureImage?.mediaName,
                                          username: signedBy.signatureImage?.username,
                                      },
                                  }
                                : undefined,
                        autofillPerson: mapStringToBoolean(autofillPerson),
                        mediaInput: isArrayWithItems(mediaInput)
                            ? mediaInput.map(media => {
                                  return {
                                      mediaName: media.mediaName || undefined,
                                      mediaLabel: media.mediaLabel || undefined,
                                      mediaType: media.mediaType || undefined,
                                      mediaDescription: media.mediaDescription || undefined,
                                      pinned: media.pinned || undefined,
                                      printInExport: media.printInExport || undefined,
                                  };
                              })
                            : undefined,
                    };
                }),
            };
        }),
        mediaOutputs: protocolSection.mediaOutputs?.map(mediaOutput => {
            return {
                _id: mediaOutput._id,
                mediaName: mediaOutput.defaultValue || undefined,
                mediaLabel: mediaOutput.label || undefined,
            };
        }),
        signedBy: protocolSection.signedBy?.map(signature => {
            return {
                requestedSignatureRole: signature.requestedSignatureRole ? [signature.requestedSignatureRole] : undefined,
                signatureRequired: mapStringToBoolean(signature.signatureRequired),
                signedByID: signature.signedByID || undefined,
                signedAt: signature.signedAt || undefined,
                signatureImage: {
                    mediaName: signature.signatureImage?.mediaName,
                    username: signature.signatureImage?.username,
                },
            };
        }),
    };
};

export const mapProtocolDocumentCharacteristicToSaveModel = (documentationCharacteristic, tiles) => {
    return {
        _id: documentationCharacteristic._id,
        column: documentationCharacteristic.column?.toString() || undefined,
        row: documentationCharacteristic.row?.toString() || undefined,
        header: documentationCharacteristic.value || undefined,
        columnWidth: documentationCharacteristic.width || undefined,
        color: documentationCharacteristic.highlighted || undefined,
        documentationCharacteristicName: documentationCharacteristic.subtitle?.value || undefined,
        imageAlignment: documentationCharacteristic.alignment || undefined,
        autoFillValue: mapStringToBoolean(documentationCharacteristic.autoFillValue) || undefined,
        gtXlsExport: documentationCharacteristic.gtXlsExport
            ? {
                  templateFile: documentationCharacteristic.gtXlsExport.templateFile || undefined,
                  worksheet: documentationCharacteristic.gtXlsExport.worksheet || undefined,
                  columnName: documentationCharacteristic.gtXlsExport.columnName || undefined,
              }
            : undefined,
        inputElement: documentationCharacteristic.inputElement?.map(input => {
            const { _id, type, checked, relevantForValidation, dropdown, signedBy, autofillPerson, mediaInput, formattedValue } = input;

            return {
                _id: _id,
                inputElementType: type,
                formattedValue: formattedValue ? JSON.stringify(mapFormattedValue(formattedValue, tiles)) : undefined,
                checked: mapStringToBoolean(checked),
                relevantForValidation: mapStringToBoolean(relevantForValidation),
                dropDown: type === inputElements.dropdown ? dropdown : undefined,
                signedBy:
                    type === inputElements.sign
                        ? {
                              requestedSignatureRole: signedBy.requestedSignatureRole ? [signedBy.requestedSignatureRole] : undefined,
                              signatureRequired: mapStringToBoolean(signedBy.signatureRequired),
                              signedByID: signedBy.signedByID || undefined,
                              signedAt: signedBy.signedAt || undefined,
                              signatureImage: {
                                  mediaName: signedBy.signatureImage?.mediaName,
                                  username: signedBy.signatureImage?.username,
                              },
                          }
                        : undefined,
                autofillPerson: mapStringToBoolean(autofillPerson),
                mediaInput: isArrayWithItems(mediaInput)
                    ? mediaInput.map(media => {
                          return {
                              mediaName: media.mediaName || undefined,
                              mediaLabel: media.mediaLabel || undefined,
                              mediaType: media.mediaType || undefined,
                              mediaDescription: media.mediaDescription || undefined,
                              pinned: media.pinned || undefined,
                              printInExport: media.printInExport || undefined,
                          };
                      })
                    : undefined,
            };
        }),
    };
};

export const mapProtocolInputElement = (input, tiles) => {
    const { _id, type, checked, relevantForValidation, dropdown, signedBy, autofillPerson, mediaInput, formattedValue } = input;

    return {
        _id: _id,
        inputElementType: type,
        formattedValue: formattedValue ? JSON.stringify(mapFormattedValue(formattedValue, tiles)) : undefined,
        checked: mapStringToBoolean(checked),
        relevantForValidation: mapStringToBoolean(relevantForValidation),
        dropDown: type === inputElements.dropdown ? dropdown : undefined,
        signedBy:
            type === inputElements.sign
                ? {
                      requestedSignatureRole: signedBy.requestedSignatureRole ? [signedBy.requestedSignatureRole] : undefined,
                      signatureRequired: mapStringToBoolean(signedBy.signatureRequired),
                      signedByID: signedBy.signedByID || undefined,
                      signedAt: signedBy.signedAt || undefined,
                      signatureImage: {
                          mediaName: signedBy.signatureImage?.mediaName,
                          username: signedBy.signatureImage?.username,
                      },
                  }
                : undefined,
        autofillPerson: mapStringToBoolean(autofillPerson),
        mediaInput: isArrayWithItems(mediaInput)
            ? mediaInput.map(media => {
                  return {
                      mediaName: media.mediaName || undefined,
                      mediaLabel: media.mediaLabel || undefined,
                      mediaType: media.mediaType || undefined,
                      mediaDescription: media.mediaDescription || undefined,
                      pinned: media.pinned || undefined,
                      printInExport: media.printInExport || undefined,
                  };
              })
            : undefined,
    };
};

export const mapTemplateToSaveModal = (template, tiles) => {
    const templateTitle = template.protocolSection.find(protocolSection => protocolSection.type === groups.title)?.value;

    let templateRevisionHistory = template.metadata.revisionHistory || [{ revisionNumber: revisionNumber[0], revisionDate: new Date() }];
    const revision = templateRevisionHistory.findIndex(x => x.revisionNumber === template.metadata.revisionNumber);
    const newRevision = {
        editor: template.metadata.revisionEditor ? { accountID: template.metadata.revisionEditor } : undefined,
        revisionNumber: template.metadata.revisionNumber || undefined,
        revisionDate: new Date(),
        revisionComment: template.metadata.revisionComment || undefined,
    };

    if (revision !== -1) {
        templateRevisionHistory[revision] = newRevision;
    } else {
        if (template.metadata.revisionNumber || template.metadata.revisionEditor || template.metadata.revisionComment) {
            templateRevisionHistory.push(newRevision);
        }
    }

    const approvalPersons = isArrayWithItems(template.metadata.templateApprover)
        ? template.metadata.templateApprover.map(x => {
              return {
                  approverID: x,
              };
          })
        : undefined;

    const tags = isArrayWithItems(template.metadata.tags)
        ? template.metadata.tags.map(x => {
              return {
                  name: x,
              };
          })
        : undefined;

    const materialType = isArrayWithItems(template.metadata.materialType)
        ? template.metadata.materialType.map(x => {
              return {
                  name: x,
              };
          })
        : undefined;

    return {
        _id: template._id,
        templateTitle: templateTitle || [],
        templateName: template.metadata.templateName,
        documentStatus: template.metadata.documentStatus,
        signedBy: template.metadata.signedBy || undefined,
        technicalReference: template.metadata.technicalReference || undefined,
        responsibleDepartment: template.metadata.responsibleDepartment ? { name: template.metadata.responsibleDepartment } : undefined,
        approvalPersons: approvalPersons,
        tags: tags,
        materialType: materialType,
        legalOwner: template.metadata.legalOwner || undefined,
        documentType: template.metadata.documentType || undefined,
        documentNumber: template.metadata.documentNumber || undefined,
        legalOwnerLogo: template.metadata.legalOwnerLogo ? { mediaName: template.metadata.legalOwnerLogo } : {},
        dateOfIssue: template.metadata.dateOfIssue || undefined,
        revisionHistory: templateRevisionHistory || [],
        protocolSections: template.protocolSection
            .filter(protocolSection => {
                return !getElementSettings()[protocolSection.type].doNotExport;
            })
            .map(protocolSection => {
                return mapProtocolSectionToSaveModel(protocolSection, tiles);
            }),
    };
};

export const mapModelToTemplate = (model, operation) => {
    let protocolTemplate = {
        _id: operation === protocolTemplateOperations.clone ? createMongoObjectId() : model._id,
        protocolSection: [],
        metadata: {
            templateName: operation !== protocolTemplateOperations.clone ? model.templateName : null,
            documentStatus: operation !== protocolTemplateOperations.clone ? model.documentStatus : documentStatus.draft,
            technicalReference: model.technicalReference,
            responsibleDepartment: model.responsibleDepartment?.name,
            templateApprover: isArrayWithItems(model.approvalPersons) ? model.approvalPersons.map(x => x.approverID) : undefined,
            tags: isArrayWithItems(model.tags) ? model.tags.map(x => x.name) : undefined,
            materialType: isArrayWithItems(model.materialType) ? model.materialType.map(x => x.name) : undefined,
            legalOwner: model.legalOwner,
            documentType: model.documentType,
            documentNumber: model.documentNumber,
            legalOwnerLogo: model.legalOwnerLogo?.mediaName,
            dateOfIssue: model.dateOfIssue,
            revisionHistory:
                isArrayWithItems(model.revisionHistory) && operation !== protocolTemplateOperations.clone
                    ? model.revisionHistory.map(item => {
                          return {
                              editor: item.editor?.accountID
                                  ? {
                                        accountID: item.editor.accountID,
                                    }
                                  : undefined,
                              revisionNumber: item.revisionNumber || undefined,
                              revisionDate: item.revisionDate || undefined,
                              revisionComment: item.revisionComment || undefined,
                          };
                      })
                    : [
                          {
                              revisionNumber: revisionNumber[0],
                              revisionDate: new Date(),
                          },
                      ],
            revisionEditor:
                isArrayWithItems(model.revisionHistory) && operation !== protocolTemplateOperations.clone
                    ? model.revisionHistory[model.revisionHistory.length - 1].editor?.accountID
                    : null,
            revisionNumber:
                isArrayWithItems(model.revisionHistory) && operation !== protocolTemplateOperations.clone
                    ? model.revisionHistory[model.revisionHistory.length - 1].revisionNumber
                    : revisionNumber[0],
            revisionComment:
                isArrayWithItems(model.revisionHistory) && operation !== protocolTemplateOperations.clone
                    ? model.revisionHistory[model.revisionHistory.length - 1].revisionComment
                    : null,
            signedBy: model.signedBy
                ? model.signedBy.map(signedBy => {
                      return {
                          signedByID: signedBy.signedByID,
                          signedAt: signedBy.signedAt,
                          requestedSignatureRole: signedBy.requestedSignatureRole,
                          signatureImage: {
                              mediaName: signedBy.signatureImage?.mediaName,
                              username: signedBy.signatureImage?.username,
                          },
                      };
                  })
                : null,
            modifiedBy: model.protocolTemplateModifiedBy,
        },
    };

    if (isArrayWithItems(model.templateTitle) && operation !== protocolTemplateOperations.clone) {
        protocolTemplate.protocolSection.push({
            _id: createMongoObjectId(),
            key: shortid.generate(),
            type: groups.title,
            value: mapValueModel(model.templateTitle),
        });
    }

    model?.protocolSections?.forEach(protocolSection => {
        protocolTemplate.protocolSection.push({
            _id: protocolSection._id,
            key: shortid.generate(),
            type: protocolSection.sectionLayoutType,
            value:
                (protocolSection.sectionLayoutType === sectionTypes.sectionParagraph &&
                    isArrayWithItems(protocolSection.sectionParagraph) &&
                    mapValueModel(protocolSection.sectionParagraph)) ||
                (isArrayWithItems(protocolSection.sectionHeading) && mapValueModel(protocolSection.sectionHeading)) ||
                undefined,
            columns: getColumnsNumber(protocolSection.documentationCharacteristic),
            rows: getRowsNumber(protocolSection.documentationCharacteristic),
            documentationCharacteristic: protocolSection.documentationCharacteristic?.map(documentationCharacteristic => {
                const { _id, column, row, header, columnWidth, color, documentationCharacteristicName, inputElement } =
                    documentationCharacteristic;

                return {
                    _id: _id,
                    key: shortid.generate(),
                    column: column !== undefined && column !== null ? parseInt(column, 10) : null,
                    row: row !== undefined && row !== null ? parseInt(row, 10) : null,
                    value: mapValueModel(header),
                    width: columnWidth,
                    highlighted: color,
                    subtitle:
                        protocolSection.sectionLayoutType === groups.list
                            ? { key: shortid.generate, _id: createMongoObjectId(), value: mapValueModel(documentationCharacteristicName) }
                            : null,
                    alignment: documentationCharacteristic.imageAlignment || undefined,
                    autoFillValue: mapBooleanToString(documentationCharacteristic.autoFillValue),
                    gtXlsExport: documentationCharacteristic.gtXlsExport
                        ? {
                              templateFile: documentationCharacteristic.gtXlsExport.templateFile || undefined,
                              worksheet: documentationCharacteristic.gtXlsExport.worksheet || undefined,
                              columnName: documentationCharacteristic.gtXlsExport.columnName || undefined,
                          }
                        : undefined,
                    inputElement: inputElement?.map(input => {
                        const {
                            _id,
                            label,
                            inputElementType,
                            checked,
                            defaultValue,
                            formattedValue,
                            helperText,
                            relevantForValidation,
                            endAdornment,
                            startAdornment,
                            isTargetElement,
                            value,
                            measurand,
                            unit,
                            targetValue,
                            lowerToleranceAbsolut,
                            upperToleranceAbsolut,
                            placeholder,
                            dropDown,
                            signedBy,
                            autofillPerson,
                            mediaInput,
                        } = input;

                        return {
                            _id: _id || createMongoObjectId(),
                            key: shortid.generate(),
                            type: inputElementType,
                            label: mapValueModel(label),
                            checked: mapBooleanToString(checked),
                            initialValidation: false,
                            defaultValue: defaultValue,
                            formattedValue: formattedValue,
                            helperText: mapValueModel(helperText),
                            relevantForValidation: mapBooleanToString(relevantForValidation),
                            endAdornment: endAdornment,
                            startAdornment: startAdornment,
                            isTargetElement: isTargetElement,
                            value: value,
                            measurand: measurand,
                            unit: unit,
                            targetValue: targetValue,
                            lowerToleranceAbsolut: lowerToleranceAbsolut,
                            upperToleranceAbsolut: upperToleranceAbsolut,
                            placeholder: mapValueModel(placeholder),
                            dropdown: {
                                defaultValue: mapValueModel(dropDown?.defaultValue),
                                options: dropDown?.options && dropDown.options.map(opt => mapValueModel(opt)),
                            },
                            signedBy: {
                                requestedSignatureRole: isArrayWithItems(signedBy?.requestedSignatureRole)
                                    ? signedBy.requestedSignatureRole[0]
                                    : '',
                                signatureRequired: mapBooleanToString(signedBy?.signatureRequired),
                                signedByID: signedBy?.signedByID,
                                signedAt: signedBy?.signedAt,
                                signatureImage: {
                                    mediaName: signedBy?.signatureImage?.mediaName,
                                    username: signedBy?.signatureImage?.username,
                                },
                            },
                            autofillPerson: mapBooleanToString(autofillPerson),
                            mediaInput: isArrayWithItems(mediaInput)
                                ? mediaInput.map(media => {
                                      return {
                                          mediaName: media.mediaName,
                                          mediaLabel: media.mediaLabel,
                                          mediaType: media.mediaType,
                                          mediaDescription: media.mediaDescription,
                                          pinned: media.pinned,
                                          printInExport: media.printInExport,
                                      };
                                  })
                                : [],
                        };
                    }),
                };
            }),
            mediaOutputs: isArrayWithItems(protocolSection.mediaOutputs)
                ? protocolSection.mediaOutputs.map(mediaOutput => {
                      return {
                          _id: mediaOutput._id,
                          key: shortid.generate(),
                          defaultValue: mediaOutput.mediaName,
                          label: mediaOutput.mediaLabel,
                          type: inputElements.mediaOutput,
                      };
                  })
                : undefined,
            signedBy: isArrayWithItems(protocolSection.signedBy)
                ? protocolSection.signedBy.map(signature => {
                      return {
                          _id: createMongoObjectId(),
                          key: shortid.generate(),
                          requestedSignatureRole: isArrayWithItems(signature?.requestedSignatureRole)
                              ? signature.requestedSignatureRole[0]
                              : '',
                          signatureRequired: mapBooleanToString(signature.signatureRequired),
                          signedByID: signature?.signedByID,
                          signedAt: signature?.signedAt,
                          signatureImage: {
                              mediaName: signature?.signatureImage?.mediaName,
                              username: signature?.signatureImage?.username,
                          },
                      };
                  })
                : undefined,
            finding: isArrayWithItems(protocolSection.resolvedTickets)
                ? protocolSection.resolvedTickets
                      .filter(ticket => ticket.references.inspectionReportID === model._id)
                      .map(ticket => {
                          return {
                              _id: ticket._id,
                              key: shortid.generate(),
                              finding: ticket.finding,
                              description: ticket.description,
                              action: ticket.action,
                              affectedSerialNumbers: ticket.affectedSerialNumbers,
                              mediaInput: ticket.mediaInput.map(media => {
                                  return {
                                      mediaName: media.mediaName,
                                  };
                              }),
                          };
                      })
                : [],
        });
    });

    return protocolTemplate;
};

export const mapDocumentationCharacteristicToTemplate = documentationCharacteristic => {
    const { _id, column, row, header, columnWidth, color, documentationCharacteristicName, inputElement } = documentationCharacteristic;

    return {
        _id: _id,
        key: shortid.generate(),
        column: column !== undefined && column !== null ? parseInt(column, 10) : null,
        row: row !== undefined && row !== null ? parseInt(row, 10) : null,
        value: mapValueModel(header),
        type: documentationCharacteristic.documentationCharacteristicType,
        width: columnWidth,
        highlighted: color,
        subtitle: mapValueModel(documentationCharacteristicName),
        alignment: documentationCharacteristic.imageAlignment || undefined,
        autoFillValue: mapBooleanToString(documentationCharacteristic.autoFillValue),
        gtXlsExport: documentationCharacteristic.gtXlsExport
            ? {
                  templateFile: documentationCharacteristic.gtXlsExport.templateFile || undefined,
                  worksheet: documentationCharacteristic.gtXlsExport.worksheet || undefined,
                  columnName: documentationCharacteristic.gtXlsExport.columnName || undefined,
              }
            : undefined,
        inputElement: inputElement?.map(input => {
            const {
                _id,
                label,
                inputElementType,
                checked,
                defaultValue,
                formattedValue,
                helperText,
                relevantForValidation,
                endAdornment,
                startAdornment,
                isTargetElement,
                value,
                measurand,
                unit,
                targetValue,
                lowerToleranceAbsolut,
                upperToleranceAbsolut,
                placeholder,
                dropDown,
                signedBy,
                autofillPerson,
                mediaInput,
            } = input;

            return {
                _id: _id || createMongoObjectId(),
                key: shortid.generate(),
                type: inputElementType,
                label: mapValueModel(label),
                checked: mapBooleanToString(checked),
                initialValidation: false,
                defaultValue: defaultValue,
                formattedValue: formattedValue,
                helperText: mapValueModel(helperText),
                relevantForValidation: mapBooleanToString(relevantForValidation),
                endAdornment: endAdornment,
                startAdornment: startAdornment,
                isTargetElement: isTargetElement,
                value: value,
                measurand: measurand,
                unit: unit,
                targetValue: targetValue,
                lowerToleranceAbsolut: lowerToleranceAbsolut,
                upperToleranceAbsolut: upperToleranceAbsolut,
                placeholder: mapValueModel(placeholder),
                dropdown: {
                    defaultValue: mapValueModel(dropDown?.defaultValue),
                    options: dropDown?.options && dropDown.options.map(opt => mapValueModel(opt)),
                },
                signedBy: {
                    requestedSignatureRole: isArrayWithItems(signedBy?.requestedSignatureRole) ? signedBy.requestedSignatureRole[0] : '',
                    signatureRequired: mapBooleanToString(signedBy?.signatureRequired),
                    signedByID: signedBy?.signedByID,
                    signedAt: signedBy?.signedAt,
                    signatureImage: {
                        mediaName: signedBy?.signatureImage?.mediaName,
                        username: signedBy?.signatureImage?.username,
                    },
                },
                autofillPerson: mapBooleanToString(autofillPerson),
                mediaInput: isArrayWithItems(mediaInput)
                    ? mediaInput.map(media => {
                          return {
                              mediaName: media.mediaName,
                              mediaLabel: media.mediaLabel,
                              mediaType: media.mediaType,
                              mediaDescription: media.mediaDescription,
                              pinned: media.pinned,
                              printInExport: media.printInExport,
                          };
                      })
                    : [],
            };
        }),
    };
};

export const mapInputModelToTemplate = inputElement => {
    const {
        _id,
        label,
        inputElementType,
        checked,
        defaultValue,
        formattedValue,
        helperText,
        relevantForValidation,
        endAdornment,
        startAdornment,
        isTargetElement,
        value,
        measurand,
        unit,
        targetValue,
        lowerToleranceAbsolut,
        upperToleranceAbsolut,
        placeholder,
        dropDown,
        signedBy,
        autofillPerson,
        mediaInput,
    } = inputElement;

    return {
        _id: _id || createMongoObjectId(),
        key: shortid.generate(),
        type: inputElementType,
        label: mapValueModel(label),
        checked: mapBooleanToString(checked),
        initialValidation: false,
        defaultValue: defaultValue,
        formattedValue: formattedValue,
        helperText: mapValueModel(helperText),
        relevantForValidation: mapBooleanToString(relevantForValidation),
        endAdornment: endAdornment,
        startAdornment: startAdornment,
        isTargetElement: isTargetElement,
        value: value,
        measurand: measurand,
        unit: unit,
        targetValue: targetValue,
        lowerToleranceAbsolut: lowerToleranceAbsolut,
        upperToleranceAbsolut: upperToleranceAbsolut,
        placeholder: mapValueModel(placeholder),
        dropdown: {
            defaultValue: mapValueModel(dropDown?.defaultValue),
            options: dropDown?.options && dropDown.options.map(opt => mapValueModel(opt)),
        },
        signedBy: {
            requestedSignatureRole: isArrayWithItems(signedBy?.requestedSignatureRole) ? signedBy.requestedSignatureRole[0] : '',
            signatureRequired: mapBooleanToString(signedBy?.signatureRequired),
            signedByID: signedBy?.signedByID,
            signedAt: signedBy?.signedAt,
            signatureImage: {
                mediaName: signedBy?.signatureImage?.mediaName,
                username: signedBy?.signatureImage?.username,
            },
        },
        autofillPerson: mapBooleanToString(autofillPerson),
        mediaInput: isArrayWithItems(mediaInput)
            ? mediaInput.map(media => {
                  return {
                      mediaName: media.mediaName,
                      mediaLabel: media.mediaLabel,
                      mediaType: media.mediaType,
                      mediaDescription: media.mediaDescription,
                      pinned: media.pinned,
                      printInExport: media.printInExport,
                  };
              })
            : [],
    };
};

export const mapSectionModelToTemplate = protocolSection => {
    return {
        _id: protocolSection._id,
        key: shortid.generate(),
        type: protocolSection.sectionLayoutType,
        value:
            (protocolSection.sectionLayoutType === sectionTypes.sectionParagraph &&
                isArrayWithItems(protocolSection.sectionParagraph) &&
                mapValueModel(protocolSection.sectionParagraph)) ||
            (isArrayWithItems(protocolSection.sectionHeading) && mapValueModel(protocolSection.sectionHeading)) ||
            undefined,
        columns: getColumnsNumber(protocolSection.documentationCharacteristic),
        rows: getRowsNumber(protocolSection.documentationCharacteristic),
        documentationCharacteristic: protocolSection.documentationCharacteristic?.map(documentationCharacteristic => {
            const { _id, column, row, header, columnWidth, color, documentationCharacteristicName, inputElement } =
                documentationCharacteristic;

            return {
                _id: _id,
                key: shortid.generate(),
                column: column !== undefined && column !== null ? parseInt(column, 10) : null,
                row: row !== undefined && row !== null ? parseInt(row, 10) : null,
                value: mapValueModel(header),
                width: columnWidth,
                highlighted: color,
                subtitle:
                    protocolSection.sectionLayoutType === groups.list
                        ? { key: shortid.generate, _id: createMongoObjectId(), value: mapValueModel(documentationCharacteristicName) }
                        : null,
                alignment: documentationCharacteristic.imageAlignment || undefined,
                autoFillValue: mapBooleanToString(documentationCharacteristic.autoFillValue),
                gtXlsExport: documentationCharacteristic.gtXlsExport
                    ? {
                          templateFile: documentationCharacteristic.gtXlsExport.templateFile || undefined,
                          worksheet: documentationCharacteristic.gtXlsExport.worksheet || undefined,
                          columnName: documentationCharacteristic.gtXlsExport.columnName || undefined,
                      }
                    : undefined,
                inputElement: inputElement?.map(input => {
                    const {
                        _id,
                        label,
                        inputElementType,
                        checked,
                        defaultValue,
                        formattedValue,
                        helperText,
                        relevantForValidation,
                        endAdornment,
                        startAdornment,
                        isTargetElement,
                        value,
                        measurand,
                        unit,
                        targetValue,
                        lowerToleranceAbsolut,
                        upperToleranceAbsolut,
                        placeholder,
                        dropDown,
                        signedBy,
                        autofillPerson,
                        mediaInput,
                    } = input;

                    return {
                        _id: _id || createMongoObjectId(),
                        key: shortid.generate(),
                        type: inputElementType,
                        label: mapValueModel(label),
                        checked: mapBooleanToString(checked),
                        initialValidation: false,
                        defaultValue: defaultValue,
                        formattedValue: formattedValue,
                        helperText: mapValueModel(helperText),
                        relevantForValidation: mapBooleanToString(relevantForValidation),
                        endAdornment: endAdornment,
                        startAdornment: startAdornment,
                        isTargetElement: isTargetElement,
                        value: value,
                        measurand: measurand,
                        unit: unit,
                        targetValue: targetValue,
                        lowerToleranceAbsolut: lowerToleranceAbsolut,
                        upperToleranceAbsolut: upperToleranceAbsolut,
                        placeholder: mapValueModel(placeholder),
                        dropdown: {
                            defaultValue: mapValueModel(dropDown?.defaultValue),
                            options: dropDown?.options && dropDown.options.map(opt => mapValueModel(opt)),
                        },
                        signedBy: {
                            requestedSignatureRole: isArrayWithItems(signedBy?.requestedSignatureRole)
                                ? signedBy.requestedSignatureRole[0]
                                : '',
                            signatureRequired: mapBooleanToString(signedBy?.signatureRequired),
                            signedByID: signedBy?.signedByID,
                            signedAt: signedBy?.signedAt,
                            signatureImage: {
                                mediaName: signedBy?.signatureImage?.mediaName,
                                username: signedBy?.signatureImage?.username,
                            },
                        },
                        autofillPerson: mapBooleanToString(autofillPerson),
                        mediaInput: isArrayWithItems(mediaInput)
                            ? mediaInput.map(media => {
                                  return {
                                      mediaName: media.mediaName,
                                      mediaLabel: media.mediaLabel,
                                      mediaType: media.mediaType,
                                      mediaDescription: media.mediaDescription,
                                      pinned: media.pinned,
                                      printInExport: media.printInExport,
                                  };
                              })
                            : [],
                    };
                }),
            };
        }),
        mediaOutputs: isArrayWithItems(protocolSection.mediaOutputs)
            ? protocolSection.mediaOutputs.map(mediaOutput => {
                  return {
                      _id: mediaOutput._id,
                      key: shortid.generate(),
                      defaultValue: mediaOutput.mediaName,
                      label: mediaOutput.mediaLabel,
                      type: inputElements.mediaOutput,
                  };
              })
            : undefined,
        signedBy: isArrayWithItems(protocolSection.signedBy)
            ? protocolSection.signedBy.map(signature => {
                  return {
                      _id: createMongoObjectId(),
                      key: shortid.generate(),
                      requestedSignatureRole: isArrayWithItems(signature?.requestedSignatureRole)
                          ? signature.requestedSignatureRole[0]
                          : '',
                      signatureRequired: mapBooleanToString(signature.signatureRequired),
                      signedByID: signature?.signedByID,
                      signedAt: signature?.signedAt,
                      signatureImage: {
                          mediaName: signature?.signatureImage?.mediaName,
                          username: signature?.signatureImage?.username,
                      },
                  };
              })
            : undefined,
        finding: isArrayWithItems(protocolSection.resolvedTickets)
            ? protocolSection.resolvedTickets.map(ticket => {
                  return {
                      _id: ticket._id,
                      key: shortid.generate(),
                      finding: ticket.finding,
                      description: ticket.description,
                      action: ticket.action,
                      affectedSerialNumbers: ticket.affectedSerialNumbers,
                      mediaInput: ticket.mediaInput.map(media => {
                          return {
                              mediaName: media.mediaName,
                          };
                      }),
                  };
              })
            : [],
    };
};

export const mapHandlerToSaveModal = (handler, tiles, modifierId) => {
    if (!isArrayWithItems(handler)) {
        return [];
    }

    let atomics = [...handler];

    // const modifiedBy = {
    //     modifierID: modifierId,
    //     modifiedAt: new Date()
    // };

    // const rootIndex = atomics.findIndex(x => !x.path);
    // if (rootIndex !== -1) {
    //     atomics = update(atomics, {
    //         [rootIndex]: {
    //             values: {
    //                 protocolTemplateModifiedBy: {
    //                     $set: modifiedBy
    //                 }
    //             }
    //         }
    //     });
    // } else {
    //     atomics = update(atomics, {
    //         $push: [
    //             {
    //                 operation: inspectionPlanHandlerOperations.update,
    //                 values: {
    //                     protocolTemplateModifiedBy: modifiedBy
    //                 }
    //             }
    //         ]
    //     });
    // }

    return atomics.map(atomic => {
        if (atomic.values.protocolSection) {
            const newAtomic = update(atomic, {
                values: {
                    protocolSection: { $set: mapProtocolSectionToSaveModel(atomic.values.protocolSection, tiles) },
                },
            });

            return newAtomic;
        }

        if (atomic.values.inputElement?.formattedValue) {
            const newAtomic = update(atomic, {
                values: {
                    inputElement: {
                        formattedValue: { $set: JSON.stringify(mapFormattedValue(atomic.values.inputElement.formattedValue, tiles)) },
                    },
                },
            });

            return newAtomic;
        }

        return atomic;
    });
};

export const getUncompletedInputElementsCount = (protocol, sectionIndex = null, characteristicIndex = null) => {
    let count = 0;

    if (isArrayWithItems(protocol?.protocolSection)) {
        protocol.protocolSection.forEach((protocolSection, protocolSectionIndex) => {
            if (
                isValueExists(sectionIndex)
                    ? protocolSectionIndex === sectionIndex
                    : true && isArrayWithItems(protocolSection?.documentationCharacteristic)
            ) {
                protocolSection.documentationCharacteristic.forEach((documentationCharacteristic, documentationCharacteristicIndex) => {
                    if (
                        isValueExists(characteristicIndex)
                            ? documentationCharacteristicIndex === characteristicIndex
                            : true && isArrayWithItems(documentationCharacteristic?.inputElement)
                    ) {
                        documentationCharacteristic.inputElement.forEach((inputElement, index, self) => {
                            if (
                                inputElement.type === inputElements.textInput ||
                                inputElement.type === inputElements.mediaInput ||
                                inputElement.type === inputElements.document ||
                                inputElement.type === inputElements.person ||
                                inputElement.type === inputElements.date
                            ) {
                                if (!inputElement.defaultValue && !inputElement.value && inputElement.relevantForValidation === 'true') {
                                    count++;
                                }
                            }

                            if (
                                inputElement.type === inputElements.radio &&
                                self.findIndex(item => item.type === inputElement.type) === index
                            ) {
                                const inputs = documentationCharacteristic.inputElement.filter(x => x.type === inputElements.radio);
                                if (
                                    inputs.every(x => !x.checked || x.checked === 'false') &&
                                    inputs.some(x => x.relevantForValidation === 'true')
                                ) {
                                    count++;
                                }
                            }
                        });
                    }
                });
            }
        });
    }

    return count;
};

export const getHandlerWithNewSigningModel = (protocol, model, userId, isUnsigning = false) => {
    const { sectionIndex, documentationCharacteristicIndex, inputElementIndex, signatureIndex, role, signatureImage, userName } = model;

    if (isValueExists(documentationCharacteristicIndex)) {
        const protocolSectionId = protocol.protocolSection[sectionIndex]._id;
        const documentationCharacteristicId =
            protocol.protocolSection[sectionIndex].documentationCharacteristic[documentationCharacteristicIndex]._id;
        const inputElementId =
            protocol.protocolSection[sectionIndex].documentationCharacteristic[documentationCharacteristicIndex].inputElement[
                inputElementIndex
            ]._id;

        const signedBy =
            protocol.protocolSection[sectionIndex].documentationCharacteristic[documentationCharacteristicIndex].inputElement[
                inputElementIndex
            ].signedBy || {};
        const newSigningModel = {
            ...signedBy,
            signatureRequired: mapStringToBoolean(signedBy.signatureRequired),
            signedByID: !isUnsigning ? userId : null,
            signedAt: new Date(),
            signatureImage: {
                mediaName: signatureImage,
                username: userName,
            },
        };
        return {
            inspectionReportId: protocol._id,
            operation: operationAtomicEnum.update,
            path: atomicInputElementPath,
            values: {
                inputElement: {
                    signedBy: newSigningModel,
                },
            },
            childrenIds: {
                protocolSectionsId: protocolSectionId,
                documentationCharacteristicId: documentationCharacteristicId,
                inputElementId: inputElementId,
            },
        };
    }

    if (isValueExists(sectionIndex)) {
        const protocolSectionId = protocol.protocolSection[sectionIndex]._id;

        const signingModel = protocol.protocolSection[sectionIndex].signedBy || [];
        const newSigningModel = signingModel.map(signature => {
            const { _id, key, ...rest } = signature;

            return {
                ...rest,
                signatureRequired: mapStringToBoolean(signature.signatureRequired),
            };
        });

        return {
            inspectionReportId: protocol._id,
            operation: operationAtomicEnum.update,
            path: `protocolSections.$[protocolSectionsId]`,
            values: {
                protocolSection: {
                    signedBy: update(newSigningModel, {
                        [signatureIndex]: {
                            signedByID: { $set: !isUnsigning ? userId : null },
                            signedAt: { $set: new Date() },
                            signatureImage: {
                                $set: {
                                    mediaName: signatureImage,
                                    username: userName,
                                },
                            },
                        },
                    }),
                },
            },
            childrenIds: {
                protocolSectionsId: protocolSectionId,
            },
        };
    }

    const signingModel = {
        signedByID: !isUnsigning ? userId : null,
        signedAt: new Date(),
        requestedSignatureRole: role ? [role] : undefined,
        signatureImage: {
            mediaName: signatureImage,
            username: userName,
        },
    };

    return {
        inspectionReportId: protocol._id,
        operation: !isUnsigning ? operationAtomicEnum.add : operationAtomicEnum.remove,
        path: 'signedBy',
        values: {
            signedBy: !isUnsigning ? signingModel : [],
        },
    };
};

export const updateProtocolWithNewSigningModel = (protocol, handleChange, activeStep, model, userId, isUnsigning = false) => {
    const { sectionIndex, documentationCharacteristicIndex, inputElementIndex, signatureIndex, role, signatureImage, userName } = model;

    if (isValueExists(documentationCharacteristicIndex)) {
        const signedBy =
            protocol.protocolSection[sectionIndex].documentationCharacteristic[documentationCharacteristicIndex].inputElement[
                inputElementIndex
            ].signedBy || [];
        const newSigningModel = signedBy
            ? {
                  ...signedBy,
                  signedByID: !isUnsigning ? userId : null,
                  signedAt: new Date(),
                  signatureImage: {
                      mediaName: signatureImage,
                      username: userName,
                  },
              }
            : null;

        handleChange(
            `rightBar.protocolTemplate.${activeStep}.protocolSection.${sectionIndex}.documentationCharacteristic.${documentationCharacteristicIndex}.inputElement.${inputElementIndex}.signedBy`,
            newSigningModel
        );

        return;
    }

    if (isValueExists(sectionIndex)) {
        const signingModel = protocol.protocolSection[sectionIndex].signedBy || [];

        handleChange(
            `rightBar.protocolTemplate.${activeStep}.protocolSection.${sectionIndex}.signedBy`,
            update(signingModel, {
                [signatureIndex]: {
                    signedByID: { $set: !isUnsigning ? userId : null },
                    signedAt: { $set: new Date() },
                    signatureImage: {
                        $set: {
                            mediaName: signatureImage,
                            username: userName,
                        },
                    },
                },
            })
        );

        return;
    }

    const protocolSignedBy = protocol.metadata.signedBy || [];
    handleChange(
        `rightBar.protocolTemplate.${activeStep}.metadata.signedBy`,
        !isUnsigning
            ? [
                  ...protocolSignedBy,
                  {
                      signedByID: !isUnsigning ? userId : null,
                      signedAt: new Date(),
                      requestedSignatureRole: role ? [role] : undefined,
                      signatureImage: {
                          mediaName: signatureImage,
                          username: userName,
                      },
                  },
              ]
            : []
    );
};

export const getDocumentDefinition = (
    language,
    template,
    users,
    tileData,
    name = '',
    inspectionOrder = null,
    hideRevisionHistory = false
) => {
    const translate = i18next.getFixedT(language.toLowerCase());

    const templateTitle = template.protocolSection.find(protocolSection => protocolSection.type === groups.title)?.value;
    const templateTitleValue = findExistingProtocolValue(templateTitle, language) || '';

    const revisionEditorUser = users.find(x => x._id === template.metadata.revisionEditor);
    const revisionEditorName = template.metadata.revisionEditor ? (revisionEditorUser ? revisionEditorUser.name : 'N/A') : '';

    const technicalReferenceUser = users.find(x => x._id === template.metadata.technicalReference);
    const technicalReferenceName = template.metadata.technicalReference
        ? technicalReferenceUser
            ? technicalReferenceUser.name
            : 'N/A'
        : '';

    const legalOwnerLogo = template.metadata.legalOwnerLogo
        ? tileData.find(
              x => x.mediaName === template.metadata.legalOwnerLogo || x.mediaName === getThumbFileName(template.metadata.legalOwnerLogo)
          )
        : null;
    const defaultLegalOwnerLogo =
        APP_CUSTOMER === reactAppCustomer.dw
            ? siemensPDFLogo
            : {
                  text: [],
                  colSpan: 3,
                  rowSpan: 2,
              };

    const approvedBy = isArrayWithItems(template.metadata.templateApprover)
        ? template.metadata.templateApprover
              .map(approver => {
                  const approverUser = users.find(x => x._id === approver);
                  return approverUser ? approverUser.name : '';
              })
              .filter(approver => approver)
              .join(', ')
        : '';

    const certificationType = findExistingProtocolValue(get(inspectionOrder, 'certificationType'), language);

    let content = [];

    if (!hideRevisionHistory) {
        content.push({
            text: translate('protocol-template-builder.make-pdf.revisionHistory.label'),
            style: 'indexHeaderStyle',
            margin: [20, 20, 20, 0],
        });

        content.push({
            style: 'table',
            table: {
                widths: [50, 75, '*', 100],
                headerRows: 0,
                body: [
                    [
                        { text: translate('protocol-template-builder.make-pdf.revisionHistory.revisionNumber'), style: 'tableHeader' },
                        { text: translate('protocol-template-builder.make-pdf.revisionHistory.revisionDate'), style: 'tableHeader' },
                        { text: translate('protocol-template-builder.make-pdf.revisionHistory.revisionComment'), style: 'tableHeader' },
                        { text: translate('protocol-template-builder.make-pdf.revisionHistory.editor'), style: 'tableHeader' },
                    ],
                    ['', '', translate('protocol-template-builder.make-pdf.revisionHistory.zero-revision.comment'), ''],
                    ...template.metadata.revisionHistory.map(item => {
                        const revisionHistoryEditorUser = users.find(x => x._id === item.editor?.accountID);
                        const revisionHistoryEditorName = item.editor?.accountID
                            ? revisionHistoryEditorUser
                                ? revisionHistoryEditorUser.name
                                : 'N/A'
                            : '';

                        return [
                            item.revisionNumber || '',
                            item.revisionDate ? moment(item.revisionDate).format(config.DEFAULT_DATE_FORMAT) : '',
                            item.revisionComment || '',
                            revisionHistoryEditorName,
                        ];
                    }),
                ],
            },
            layout: 'lightHorizontalLines',
        });
    }

    content.push({
        toc: {
            title: { text: translate('protocol-template-builder.make-pdf.contents'), style: 'indexHeaderStyle' },
        },
        style: 'indexStyle',
        pageBreak: 'after',
    });

    if (templateTitleValue) {
        content.push({
            text: templateTitleValue,
            style: 'templateTitle',
            margin: [20, 20, 20, 20],
        });
    }

    template.protocolSection.forEach(section => {
        if (section.type === groups.sectionHeading) {
            const value = findExistingProtocolValue(section.value, language);
            if (value) {
                content.push({
                    text: value,
                    style: 'header',
                    tocItem: true,
                });
            }
        }
        if (section.type === groups.sectionParagraph) {
            const value = findExistingProtocolValue(section.value, language);
            if (value) {
                content.push({
                    text: value,
                    style: 'paragraph',
                });
            }
        }
        if (section.type === groups.headerGrid) {
            const groups = groupBy(section.documentationCharacteristic, 'row');

            const rows = Object.keys(groups).map(x =>
                groups[x].sort((obj1, obj2) => {
                    return obj1.column - obj2.column;
                })
            );

            content.push({
                columns: [
                    {
                        style: 'table',
                        table: {
                            widths: '*',
                            heights: 25,
                            headerRows: 0,
                            body: [
                                ...rows.map(row => {
                                    return row.map(characteristic => {
                                        const customClassses = {
                                            label: 'boldLabel',
                                            inputField: 'boldInputField',
                                        };
                                        return getInputElementDefinition(characteristic.inputElement, language, null, customClassses);
                                    });
                                }),
                            ],
                        },
                    },
                ],
            });
        }

        if (section.type === groups.table) {
            const mediaIntupsName = [];
            const groups = groupBy(section.documentationCharacteristic, 'row');

            section?.documentationCharacteristic?.forEach(element => {
                if (element.inputElement?.length > 0) {
                    element.inputElement?.forEach(item => {
                        if (item.mediaInput?.length > 0) {
                            item.mediaInput?.forEach(e => {
                                if (e.mediaName) {
                                    mediaIntupsName.push({ mediaName: e.mediaName, mediaLabel: e.mediaLabel });
                                }
                            });
                        }
                    });
                }
            });

            const headers = groups['0'].sort((obj1, obj2) => {
                return obj1.column - obj2.column;
            });
            const rows = Object.keys(groups)
                .filter(x => x !== '0')
                .map(x =>
                    groups[x].sort((obj1, obj2) => {
                        return obj1.column - obj2.column;
                    })
                );

            const mediaOutputs = section.mediaOutputs
                ? section.mediaOutputs
                      .map(mediaOutput => {
                          const mediaName = mediaOutput.defaultValue;

                          const mediaData = tileData.find(x => x.mediaName === mediaName || x.mediaName === getThumbFileName(mediaName));
                          return mediaData ? mediaData.mediaBase64 : null;
                      })
                      .filter(mediaOutput => mediaOutput)
                : null;

            let columns = [];
            columns.push({
                width: isArrayWithItems(mediaOutputs) ? 400 : undefined,
                style: 'table',
                table: {
                    widths: 'auto',
                    headerRows: 0,
                    body: [
                        headers.map(documentationCharacteristic => {
                            return {
                                text: findExistingProtocolValue(documentationCharacteristic.value, language),
                                style: 'tableHeader',
                            };
                        }),
                        ...rows.map(row => {
                            return row.map(characteristic => {
                                return getInputElementDefinition(characteristic.inputElement, language, tileData);
                            });
                        }),
                    ],
                },
                layout: 'lightHorizontalLines',
            });

            const mediaInputs = [];

            mediaIntupsName.forEach(mediaInput => {
                const mediaData = tileData.find(
                    x => x.mediaName === mediaInput.mediaName || x.mediaName === getThumbFileName(mediaInput.mediaName)
                );

                if (mediaData) {
                    mediaInputs.push({ mediaName: mediaData ? mediaData.mediaBase64 : null, mediaLabel: mediaInput.mediaLabel });
                }
            });

            let list = [];

            if (isArrayWithItems(mediaInputs)) {
                list.push([
                    { text: 'Media Inputs', alignment: 'center', bold: true },
                    {
                        ul: mediaInputs.map(mediaInput => {
                            return [
                                {
                                    header: 'Media Inputs',
                                    image: mediaInput.mediaName,
                                    width: 250,
                                    alignment: 'center',
                                    margin: [0, 25, 0, 10],
                                },
                                { text: mediaInput.mediaLabel, alignment: 'center' },
                            ];
                        }),
                    },
                ]);
            }

            if (isArrayWithItems(mediaOutputs)) {
                columns.push({
                    stack: mediaOutputs.map(mediaOutput => {
                        return {
                            width: 150,
                            image: mediaOutput,
                            fit: [150, 150],
                            margin: [0, 15, 0, 0],
                        };
                    }),
                });
            }

            content.push({
                columns: columns,
            });

            content.push({
                columns: list,
            });

            if (isArrayWithItems(section.signedBy)) {
                const signatures = [
                    {
                        text: translate('protocol-template-builder.make-pdf.signedBy.title'),
                        style: 'signature',
                        bold: true,
                        margin: [0, 20, 0, 0],
                    },
                ];

                section.signedBy.forEach(signature => {
                    const mediaSignature = tileData.find(
                        x =>
                            x.mediaName === signature.signatureImage.mediaName ||
                            x.mediaName === getThumbFileName(signature.signatureImage.mediaName)
                    );
                    const media = mediaSignature ? mediaSignature.mediaBase64 : null;
                    const signatureUser = users.find(x => x._id === signature.signedByID);
                    const signatureUserName =
                        Boolean(signature.signedByID) && Boolean(signatureUser)
                            ? `${signatureUser.name || ''}, ${signatureUser.firstName || ''}`
                            : signature.signatureImage.username || 'N/A';

                    if (signatureUser) {
                        signatures.push({
                            text: [
                                translate('protocol-template-builder.make-pdf.signedBy.content', {
                                    userName: signatureUserName,
                                    dateTime: moment(signature.signedAt).format(config.DEFAULT_FULL_DATE_FORMAT),
                                }),
                            ],
                            style: 'signature',
                            margin: [0, 0, 0, 0],
                        });

                        const list = [signatures];

                        if (media) {
                            list.push({
                                width: 100,
                                image: media,
                                fit: [100, 100],
                                margin: [0, 0, 0, 0],
                            });
                        }

                        content.push({
                            style: 'list',
                            table: {
                                widths: 'auto',
                                body: [list],
                            },
                            layout: 'lightHorizontalLines',
                            unbreakable: true,
                        });
                    }
                });
            }
        }

        if (section.type === groups.list) {
            const mediaIntupsName = [];

            let columns = [];

            section?.documentationCharacteristic?.forEach(element => {
                if (element.inputElement?.length > 0) {
                    element.inputElement?.forEach(item => {
                        if (item.mediaInput?.length > 0) {
                            item.mediaInput?.forEach(e => {
                                if (e.mediaName) {
                                    mediaIntupsName.push({ mediaName: e.mediaName, mediaLabel: e.mediaLabel });
                                }
                            });
                        }
                    });
                }
            });

            const mediaInputs = [];

            mediaIntupsName.forEach(mediaInput => {
                const mediaData = tileData.find(
                    x => x.mediaName === mediaInput.mediaName || x.mediaName === getThumbFileName(mediaInput.mediaName)
                );

                if (mediaData) {
                    mediaInputs.push({ mediaName: mediaData ? mediaData.mediaBase64 : null, mediaLabel: mediaInput.mediaLabel });
                }
            });

            let list = [];

            section.documentationCharacteristic.forEach((characteristic, characteristicIndex) => {
                const characteristicDefinition = getDocumentationCharacteristicDefinition(characteristic, language, tileData);

                list.push({
                    style: 'list',
                    table: {
                        widths: characteristicDefinition.widths,
                        body: [characteristicDefinition.values],
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            if (characteristicIndex === section.documentationCharacteristic.length - 1) {
                                return 0;
                            } else {
                                return i !== 0 ? 0.5 : 0;
                            }
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                    },
                });
            });

            const mediaOutputs = section.mediaOutputs
                ? section.mediaOutputs
                      .map(mediaOutput => {
                          const mediaName = mediaOutput.defaultValue;

                          const mediaData = tileData.find(x => x.mediaName === mediaName || x.mediaName === getThumbFileName(mediaName));
                          return mediaData ? mediaData.mediaBase64 : null;
                      })
                      .filter(mediaOutput => mediaOutput)
                : null;

            columns.push({
                width: isArrayWithItems(mediaOutputs) ? 400 : '*',
                stack: list,
                margin: [0, 10, 0, 10],
            });

            if (isArrayWithItems(mediaOutputs)) {
                columns.push({
                    width: 'auto',
                    stack: mediaOutputs.map(mediaOutput => {
                        return {
                            width: 150,
                            image: mediaOutput,
                            fit: [150, 150],
                            margin: [0, 15, 0, 0],
                        };
                    }),
                });
            }

            content.push({
                columns: columns,
            });

            let mediaInputList = [];

            if (isArrayWithItems(mediaInputs)) {
                mediaInputList.push([
                    { text: 'Media Inputs', alignment: 'center', bold: true },
                    {
                        ul: mediaInputs.map(mediaInput => {
                            return [
                                {
                                    header: 'Media Inputs',
                                    image: mediaInput.mediaName,
                                    width: 250,
                                    alignment: 'center',
                                    margin: [0, 25, 0, 10],
                                },
                                { text: mediaInput.mediaLabel, alignment: 'center' },
                            ];
                        }),
                    },
                ]);
            }

            content.push(mediaInputList);

            if (isArrayWithItems(section.signedBy)) {
                const signatures = [
                    {
                        text: translate('protocol-template-builder.make-pdf.signedBy.title'),
                        style: 'signature',
                        bold: true,
                        margin: [0, 20, 0, 0],
                    },
                ];

                section.signedBy.forEach(signature => {
                    const mediaSignature = tileData.find(
                        x =>
                            x.mediaName === signature.signatureImage.mediaName ||
                            x.mediaName === getThumbFileName(signature.signatureImage.mediaName)
                    );
                    const media = mediaSignature ? mediaSignature.mediaBase64 : null;
                    const signatureUser = users.find(x => x._id === signature.signedByID);
                    const signatureUserName =
                        Boolean(signature.signedByID) && Boolean(signatureUser)
                            ? `${signatureUser.name || ''}, ${signatureUser.firstName || ''}`
                            : signature.signatureImage.username || 'N/A';

                    if (signatureUser) {
                        signatures.push({
                            text: [
                                translate('protocol-template-builder.make-pdf.signedBy.content', {
                                    userName: signatureUserName,
                                    dateTime: moment(signature.signedAt).format(config.DEFAULT_FULL_DATE_FORMAT),
                                }),
                            ],
                            style: 'signature',
                            margin: [0, 0, 0, 0],
                        });

                        const list = [signatures];

                        if (media) {
                            list.push({
                                width: 100,
                                image: media,
                                fit: [100, 100],
                                margin: [0, 0, 0, 0],
                            });
                        }

                        content.push({
                            style: 'list',
                            table: {
                                widths: 'auto',
                                body: [list],
                            },
                            layout: 'lightHorizontalLines',
                            unbreakable: true,
                        });
                    }
                });
            }
        }

        if (section.type === groups.imageSection) {
            section.documentationCharacteristic.forEach(characteristic => {
                let mediaColumns = [[], []];

                if (isArrayWithItems(characteristic.inputElement)) {
                    characteristic.inputElement
                        .map(inputElement => {
                            if (inputElement.type === inputElements.mediaOutput) {
                                const mediaName = inputElement.defaultValue;
                                const mediaData = tileData.find(
                                    x => x.mediaName === mediaName || x.mediaName === getThumbFileName(mediaName)
                                );

                                const label = findExistingProtocolValue(inputElement.label, language) || '';

                                return {
                                    mediaData: mediaData,
                                    label: label,
                                };
                            }
                            return null;
                        })
                        .filter(inputElement => {
                            return !!inputElement?.mediaData;
                        })
                        .forEach((inputElement, inputElementIndex) => {
                            const colIndex = inputElementIndex % 2;

                            mediaColumns[colIndex].push({
                                image: inputElement.mediaData.mediaBase64,
                                alignment: 'center',
                                width: 250,
                                fit: [252, 252],
                                margin: [0, 0, 0, 10],
                            });

                            mediaColumns[colIndex].push({ text: inputElement.label, style: 'mediaCaption' });
                        });
                }

                if (mediaColumns[0].length || mediaColumns[1].length) {
                    content.push({
                        margin: [0, 20, 0, 0],
                        columns: mediaColumns,
                    });
                }
            });
        }
    });

    if (isArrayWithItems(template.metadata.signedBy)) {
        const signatures = [
            {
                text: translate('protocol-template-builder.make-pdf.signedBy.title'),
                style: 'signature',
                bold: true,
                margin: [0, 20, 0, 0],
            },
        ];

        template.metadata.signedBy.forEach(signature => {
            const mediaSignature = tileData.find(
                x =>
                    x.mediaName === signature.signatureImage.mediaName ||
                    x.mediaName === getThumbFileName(signature.signatureImage.mediaName)
            );
            const media = mediaSignature ? mediaSignature.mediaBase64 : null;
            const signatureUser = users.find(x => x._id === signature.signedByID);
            const signatureUserName =
                Boolean(signature.signedByID) && Boolean(signatureUser)
                    ? `${signatureUser.name || ''}, ${signatureUser.firstName || ''}`
                    : signature.signatureImage.username || 'N/A';

            if (signatureUser) {
                signatures.push({
                    text: [
                        translate('protocol-template-builder.make-pdf.signedBy.content', {
                            userName: signatureUserName,
                            dateTime: moment(signature.signedAt).format(config.DEFAULT_FULL_DATE_FORMAT),
                        }),
                    ],
                    style: 'signature',
                    margin: [0, 0, 0, 0],
                });

                const list = [signatures];

                if (media) {
                    list.push({
                        width: 100,
                        image: media,
                        fit: [100, 100],
                        margin: [0, 10, 0, 0],
                    });
                }

                content.push({
                    table: {
                        widths: 'auto',
                        body: [list],
                    },
                    layout: 'lightHorizontalLines',
                    margin: [20, 20, 20, 20],
                    unbreakable: true,
                });
            }
        });
    }

    return {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [20, 40, 30, 160],
        info: {
            title: name || template.metadata.templateName || '',
        },
        content: content,
        background: function (currentPage, pageSize) {
            return [
                {
                    canvas: [
                        { type: 'line', x1: 20, y1: 20, x2: 575, y2: 20, lineWidth: 1 }, //Up line
                        { type: 'line', x1: 20.5, y1: 20, x2: 20.5, y2: 702, lineWidth: 1 }, //Left line
                        { type: 'line', x1: 574.75, y1: 20, x2: 574.75, y2: 702, lineWidth: 1 }, //Rigth line
                    ],
                },
            ];
        },
        footer: function (currentPage, pageCount, pageSize) {
            return [
                {
                    style: 'footer',
                    color: '#444',
                    table: {
                        widths: [30, 30, 30, 30, 30, 30, 30, 30, 30, '*', 30, 30],
                        heights: [20, 20, 20, 20],
                        body: [
                            [
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.responsibleDepartment')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: template.metadata.responsibleDepartment, style: 'footerNormal' },
                                    ],
                                    colSpan: 3,
                                    rowSpan: 1,
                                },
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.technicalReference')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: technicalReferenceName, style: 'footerNormal' },
                                    ],
                                    colSpan: 2,
                                    rowSpan: 1,
                                },
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.createdBy')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: revisionEditorName, style: 'footerNormal' },
                                    ],
                                    colSpan: 2,
                                    rowSpan: 1,
                                },
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.approvedBy')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: approvedBy, style: 'footerNormal' },
                                    ],
                                    colSpan: 2,
                                    rowSpan: 1,
                                },
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.project')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: '', style: 'footerNormal' },
                                    ],
                                    colSpan: 3,
                                    rowSpan: 1,
                                },
                                {},
                                {},
                            ],
                            [
                                legalOwnerLogo
                                    ? {
                                          image: legalOwnerLogo.mediaBase64,
                                          margin: [4, 0, 0, 0],
                                          fit: [100, 50],
                                          width: 100,
                                          height: 50,
                                          colSpan: 3,
                                          rowSpan: 2,
                                      }
                                    : defaultLegalOwnerLogo,
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.documentType')}\n`,
                                            style: 'footerSmall',
                                        },
                                        {
                                            text: certificationType
                                                ? i18next.t(certificationDocumentTypesTranslations[certificationType])
                                                : template.metadata.documentType || '',
                                            style: 'footerNormal',
                                        },
                                    ],
                                    colSpan: 5,
                                },
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.documentStatus')}\n`,
                                            style: 'footerSmall',
                                        },
                                        {
                                            text: translate(`common.document-status.${template.metadata.documentStatus}`),
                                            style: 'footerNormal',
                                        },
                                    ],
                                    colSpan: 2,
                                },
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.customer')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: '', style: 'footerNormal' },
                                    ],
                                    colSpan: 2,
                                    rowSpan: 2,
                                },
                                {},
                            ],
                            [
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        { text: `${translate('protocol-template-builder.make-pdf.footer.title')}\n`, style: 'footerSmall' },
                                        { text: templateTitleValue, style: 'footerNormal' },
                                    ],
                                    colSpan: 5,
                                    rowSpan: 2,
                                },
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.documentNumber')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: template.metadata.templateName, style: 'footerNormal' },
                                    ],
                                    colSpan: 2,
                                },
                                {},
                                {},
                                {},
                            ],
                            [
                                {
                                    text: template.metadata.legalOwner ? `© ${template.metadata.legalOwner}` : '',
                                    style: 'footerMedium',
                                    colSpan: 3,
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.revision')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: template.metadata.revisionNumber, style: 'footerNormal' },
                                    ],
                                },
                                {
                                    text: [
                                        { text: `${translate('protocol-template-builder.make-pdf.footer.date')}\n`, style: 'footerSmall' },
                                        {
                                            text: template.metadata.dateOfIssue
                                                ? moment(template.metadata.dateOfIssue).format(config.DEFAULT_DATE_FORMAT)
                                                : '',
                                            style: 'footerNormal',
                                        },
                                    ],
                                },
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.language')}\n`,
                                            style: 'footerSmall',
                                        },
                                        { text: language, style: 'footerNormal' },
                                    ],
                                },
                                {
                                    text: [
                                        {
                                            text: `${translate('protocol-template-builder.make-pdf.footer.page.title')}\n`,
                                            style: 'footerSmall',
                                        },
                                        {
                                            text: translate('protocol-template-builder.make-pdf.footer.page.number', {
                                                page: currentPage,
                                                count: pageCount,
                                            }),
                                            style: 'footerNormal',
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    text: translate('protocol-template-builder.make-pdf.footer.confidential'),
                                    style: 'footerSmall',
                                    alignment: 'center',
                                    colSpan: 12,
                                    border: [false, true, false, false],
                                },
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                            ],
                        ],
                    },
                    margin: [20, 20, 20, 20],
                },
            ];
        },
        styles: {
            indexHeaderStyle: {
                fontSize: 11,
                bold: true,
                alignment: 'left',
                margin: [0, 0, 0, 15],
            },
            indexStyle: {
                fontSize: 11,
                bold: false,
                alignment: 'right',
                margin: [20, 20, 20, 20],
            },
            templateTitle: {
                fontSize: 18,
                bold: true,
                alignment: 'center',
                margin: [20, 20, 20, 20],
            },
            header: {
                fontSize: 11,
                bold: true,
                margin: [20, 20, 0, 15],
            },
            paragraph: {
                fontSize: 11,
                margin: [20, 0, 0, 15],
                lineHeight: 1.5,
            },
            table: {
                margin: [20, 15, 15, 20],
                fontSize: 11,
                alignment: 'left',
            },
            list: {
                widths: [100, '*', 200, '*'],
                margin: [20, 0, 20, 0],
                fontSize: 11,
                alignment: 'left',
            },
            tableHeader: {
                bold: false,
                fontSize: 11,
                color: 'black',
                fillColor: 'grey',
                fillOpacity: 0.1,
                alignment: 'left',
            },
            footer: {
                margin: [20, 15, 15, 20],
                fontSize: 11,
                alignment: 'left',
            },
            footerNormal: {
                fontSize: 10,
            },
            footerMedium: {
                fontSize: 9,
            },
            footerSmall: {
                fontSize: 6,
            },
            mediaCaption: {
                alignment: 'center',
                fontSize: '10',
                margin: [0, 0, 0, 20],
            },
            label: {
                italics: false,
                color: 'grey',
            },
            boldLabel: {
                fontSize: 12,
                bold: true,
            },
            unit: {
                fontSize: 9,
            },
            adornment: {
                fontSize: 11,
            },
            inputField: {},
            boldInputField: {
                fontSize: 12,
                bold: true,
            },
            signature: {
                fontSize: 9,
                italics: true,
                margin: [20, 0, 0, 0],
            },
        },
    };
};

export const getInputElementDefinition = (inputElement, language, tileData = [], customClassses = null) => {
    let result = {
        text: [],
    };

    if (isArrayWithItems(inputElement)) {
        inputElement.forEach(input => {
            if (input.type === inputElements.staticText) {
                result = {
                    text: [
                        { text: findExistingProtocolValue(input.label, language) || '', style: customClassses?.inputField || 'inputField' },
                    ],
                };
            }

            if (input.type === inputElements.textInput) {
                result = {
                    stack: [
                        {
                            text: [
                                { text: findExistingProtocolValue(input.label, language) || '', style: customClassses?.label || 'label' },
                            ],
                        },
                        {
                            text: [
                                { text: input.startAdornment || '', style: 'adornment' },
                                { text: input.value || input.defaultValue || '', style: 'inputField' },
                                { text: input.endAdornment || '', style: 'adornment' },
                                { text: input.unit ? ` ${input.unit}` : '', style: 'unit' },
                            ],
                        },
                    ],
                };
            }

            if (input.type === inputElements.formattedTextInput) {
                result = {
                    text: [{ text: input.value || input.defaultValue || '', style: customClassses?.inputField || 'inputField' }],
                };
            }

            if (input.type === inputElements.date) {
                const dateValue = input.value || input.defaultValue || '';

                result = {
                    stack: [
                        {
                            text: [
                                { text: findExistingProtocolValue(input.label, language) || '', style: customClassses?.label || 'label' },
                            ],
                        },
                        {
                            text: [{ text: dateValue ? moment(dateValue).format(config.DEFAULT_DATE_FORMAT) : '', style: 'inputField' }],
                        },
                    ],
                };
            }

            if (input.type === inputElements.person) {
                result = {
                    stack: [
                        {
                            text: [
                                { text: findExistingProtocolValue(input.label, language) || '', style: customClassses?.label || 'label' },
                            ],
                        },
                        {
                            text: [{ text: input.value || input.defaultValue || '', style: 'inputField' }],
                        },
                    ],
                };
            }

            if (input.type === inputElements.dropdown) {
                const defaultValue = findExistingProtocolValue(input.dropdown?.defaultValue, language);

                result = {
                    stack: [
                        { text: [{ text: findExistingProtocolValue(input.label, language) || '', style: 'label' }] },
                        { text: [{ text: input.value || defaultValue || '', style: 'inputField' }] },
                    ],
                };
            }

            if (input.type === inputElements.radio && input.checked === 'true') {
                result = {
                    text: [{ text: findExistingProtocolValue(input.label, language) || '', style: 'inputField' }],
                };
            }

            if (input.type === inputElements.mediaInput) {
                const mediaName = (input.value && input.value.split('/').filter(x => x)[0]) || '';
                const mediaData = tileData.find(x => x.mediaName === mediaName || x.mediaName === getThumbFileName(mediaName));

                if (mediaData) {
                    result = {
                        stack: [
                            // { text: [{ text: findExistingProtocolValue(input.label, language) || "", style: 'label' }] },
                            {
                                image: mediaData.mediaBase64,
                                alignment: 'center',
                                fit: [150, 252],
                                margin: [0, 0, 0, 10],
                            },
                        ],
                    };
                }
            }

            if (input.type === inputElements.document) {
                result = {
                    text: [{ text: input.value || '', style: 'inputField' }],
                };
            }
        });
    }

    return result;
};

export const getDocumentationCharacteristicDefinition = (characteristic, language, tileData = []) => {
    let widths = [];
    let values = [];

    if (isArrayWithItems(characteristic.subtitle?.value)) {
        widths.push(150);
        values.push({ text: [{ text: findExistingProtocolValue(characteristic.subtitle.value, language) || '', style: 'inputField' }] });
    }

    if (isArrayWithItems(characteristic.inputElement)) {
        characteristic.inputElement.forEach(input => {
            if (input.type === inputElements.staticText) {
                const inputValue = findExistingProtocolValue(input.label, language) || '';

                widths.push('*');
                values.push({
                    stack: [{ text: [{ text: inputValue, style: 'inputField' }] }],
                });
            }

            if (input.type === inputElements.textInput) {
                const inputLabel = findExistingProtocolValue(input.label, language) || '';
                const inputValue = input.value || input.defaultValue || '';

                widths.push('*');
                values.push({
                    stack: [
                        { text: [{ text: inputLabel, style: 'label' }] },
                        {
                            text: [
                                { text: input.startAdornment || '', style: 'adornment' },
                                { text: inputValue, style: 'inputField' },
                                { text: input.endAdornment || '', style: 'adornment' },
                                { text: input.unit ? ` ${input.unit}` : '', style: 'unit' },
                            ],
                        },
                    ],
                });
            }

            if (input.type === inputElements.formattedTextInput) {
                const inputValue = input.value || input.defaultValue;

                widths.push('*');
                values.push({
                    stack: [{ text: [{ text: inputValue, style: 'inputField' }] }],
                });
            }

            if (input.type === inputElements.date) {
                const inputLabel = findExistingProtocolValue(input.label, language) || '';
                const inputValue = input.value || input.defaultValue || '';

                widths.push('*');
                values.push({
                    stack: [
                        { text: [{ text: inputLabel, style: 'label' }] },
                        { text: [{ text: inputValue ? moment(inputValue).format(config.DEFAULT_DATE_FORMAT) : '', style: 'inputField' }] },
                    ],
                });
            }

            if (input.type === inputElements.person) {
                const inputLabel = findExistingProtocolValue(input.label, language) || '';
                const inputValue = input.value || input.defaultValue || '';

                widths.push('*');
                values.push({
                    stack: [{ text: [{ text: inputLabel, style: 'label' }] }, { text: [{ text: inputValue, style: 'inputField' }] }],
                });
            }

            if (input.type === inputElements.dropdown) {
                const inputLabel = findExistingProtocolValue(input.label, language) || '';
                const defaultValue = findExistingProtocolValue(input.dropdown?.defaultValue, language);
                const inputValue = input.value || defaultValue || '';

                widths.push('*');
                values.push({
                    stack: [{ text: [{ text: inputLabel, style: 'label' }] }, { text: [{ text: inputValue, style: 'inputField' }] }],
                });
            }

            if (input.type === inputElements.radio && input.checked === 'true') {
                widths.push(50);
                values.push({ text: [{ text: findExistingProtocolValue(input.label, language) || '', style: 'inputField' }] });
            }

            if (input.type === inputElements.mediaInput) {
                const mediaName = (input.value && input.value.split('/').filter(x => x)[0]) || '';
                const mediaData = tileData.find(x => x.mediaName === mediaName || x.mediaName === getThumbFileName(mediaName));

                if (mediaData) {
                    widths.push(150);
                    values.push({
                        stack: [
                            // { text: [{ text: findExistingProtocolValue(input.label, language) || "", style: 'label' }] },
                            {
                                image: mediaData.mediaBase64,
                                alignment: 'center',
                                fit: [150, 252],
                                margin: [0, 0, 0, 10],
                            },
                        ],
                    });
                }
            }

            if (input.type === inputElements.mediaOutput) {
                const mediaName = input.defaultValue;
                const mediaData = tileData.find(x => x.mediaName === mediaName || x.mediaName === getThumbFileName(mediaName));

                if (mediaData) {
                    widths.push(150);
                    values.push({
                        stack: [
                            // { text: [{ text: findExistingProtocolValue(input.label, language) || "", style: 'label' }] },
                            {
                                image: mediaData.mediaBase64,
                                alignment: 'center',
                                fit: [150, 252],
                                margin: [0, 0, 0, 10],
                            },
                        ],
                    });
                }
            }

            if (input.type === inputElements.document) {
                widths.push('*');
                values.push({
                    stack: [
                        {
                            text: [{ text: input.value || '', style: 'inputField' }],
                        },
                    ],
                });
            }
        });
    }

    return {
        widths: widths,
        values: values,
    };
};

export const getMetadataSignedTileData = (signed, tileData) => {
    let filteredTileData = [];

    signed.forEach(element => {
        if (element.signatureImage.mediaName) {
            const tile = findTileElement(element.signatureImage.mediaName, tileData);

            if (tile) {
                filteredTileData.push(tile);
            }
        }
    });

    return filteredTileData;
};

export const getProtocolFilteredTileData = (protocolTemplate, tileData) => {
    let filteredTileData = [];
    if (isArrayWithItems(protocolTemplate.protocolSection)) {
        for (let sectionIndex = 0; sectionIndex < protocolTemplate.protocolSection.length; sectionIndex++) {
            const protocolSection = protocolTemplate.protocolSection[sectionIndex];

            protocolSection?.documentationCharacteristic?.forEach(element => {
                if (element.inputElement?.length > 0) {
                    element.inputElement?.forEach(item => {
                        if (item.mediaInput?.length > 0) {
                            item.mediaInput?.forEach(e => {
                                const tile = findTileElement(e.mediaName, tileData);
                                if (tile) {
                                    filteredTileData.push(tile);
                                }
                            });
                        }
                    });
                }
            });

            protocolSection?.signedBy?.forEach(element => {
                if (element.signatureImage.mediaName) {
                    const tile = findTileElement(element.signatureImage.mediaName, tileData);

                    if (tile) {
                        filteredTileData.push(tile);
                    }
                }
            });

            if (isArrayWithItems(protocolSection.mediaOutputs)) {
                for (let mediaIndex = 0; mediaIndex < protocolSection.mediaOutputs.length; mediaIndex++) {
                    const mediaOutput = protocolSection.mediaOutputs[mediaIndex];

                    if (mediaOutput.defaultValue) {
                        const tile = findTileElement(mediaOutput.defaultValue, tileData);
                        if (tile) {
                            filteredTileData.push(tile);
                        }
                    }
                }
            }

            if (isArrayWithItems(protocolSection.documentationCharacteristic)) {
                for (
                    let documentationCharacteristicIndex = 0;
                    documentationCharacteristicIndex < protocolSection.documentationCharacteristic.length;
                    documentationCharacteristicIndex++
                ) {
                    const documentationCharacteristic = protocolSection.documentationCharacteristic[documentationCharacteristicIndex];

                    if (isArrayWithItems(documentationCharacteristic.inputElement)) {
                        for (
                            let inputElementIndex = 0;
                            inputElementIndex < documentationCharacteristic.inputElement.length;
                            inputElementIndex++
                        ) {
                            const inputElement = documentationCharacteristic.inputElement[inputElementIndex];

                            if (inputElement.type === inputElements.mediaOutput && inputElement.defaultValue) {
                                const tile = findTileElement(inputElement.defaultValue, tileData);

                                if (tile) {
                                    filteredTileData.push(tile);
                                }
                            }
                            if (inputElement.type === inputElements.mediaInput && inputElement.value) {
                                const mediaName = inputElement.value.split('/').filter(x => x)[0];
                                const tile = findTileElement(mediaName, tileData);

                                if (tile) {
                                    filteredTileData.push(tile);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (protocolTemplate.metadata.legalOwnerLogo) {
        const tile = findTileElement(protocolTemplate.metadata.legalOwnerLogo, tileData);
        if (tile) {
            filteredTileData.push(tile);
        }
    }

    filteredTileData = filteredTileData.filter(tile =>
        allowedFileTypes.images.some(ext => getFileExtension(ext) === getFileExtension(tile.url).toLowerCase())
    );

    return filteredTileData;
};

export const trackEntity = (id, type) => {
    const storageProtocolHistory = JSON.parse(localStorage.getItem('protocolHistory')) || [];

    const index = storageProtocolHistory.findIndex(x => x._id === id && x.type === type);

    if (index !== -1) {
        const newItems = update(storageProtocolHistory, {
            [index]: {
                date: { $set: new Date() },
                count: { $set: storageProtocolHistory[index].count + 1 },
            },
        });

        localStorage.setItem('protocolHistory', JSON.stringify(newItems));
    } else {
        const newItems = update(storageProtocolHistory, {
            $push: [
                {
                    _id: id,
                    type: type,
                    date: new Date(),
                    count: 1,
                },
            ],
        });

        localStorage.setItem('protocolHistory', JSON.stringify(newItems));
    }
};

export const findInputElementByLabel = (section, labelObject) => {
    if (!section || !isArrayWithItems(section.documentationCharacteristic)) {
        return null;
    }

    for (let characteristicIndex = 0; characteristicIndex < section.documentationCharacteristic.length; characteristicIndex++) {
        const characteristic = section.documentationCharacteristic[characteristicIndex];

        if (!isArrayWithItems(characteristic.inputElement)) {
            return null;
        }

        for (let inputElementIndex = 0; inputElementIndex < characteristic.inputElement.length; inputElementIndex++) {
            const inputElement = characteristic.inputElement[inputElementIndex];

            if (!isArrayWithItems(inputElement.label)) {
                return null;
            }

            const labelKeys = Object.keys(labelObject);

            for (let labelIndex = 0; labelIndex < labelKeys.length; labelIndex++) {
                const labelKey = labelKeys[labelIndex];
                const label = inputElement.label.find(x => x.language === labelKey);

                if (label.value === labelObject[labelKey]) {
                    return {
                        characteristicIndex,
                        inputElementIndex,
                    };
                }
            }
        }
    }

    return null;
};
