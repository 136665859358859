import moment from 'moment';

import { shiftValues } from '../constants';

import { ticketDetailsActions } from './ticketDetails.actions';

const initialCurrentEntry = {
    _id: null,
    name: '',
    shift: shiftValues.shift1,
    shiftStart: moment(),
    shiftEnd: null,
    machineDowntime: 0,
};

const ticketDetailsInitialState = {
    ticket: null,
    ticketOverleyData: {
        isOpen: false,
        ticketId: null,
    },
    asset: null,
    isDataSaved: true,
    valError: false,
    currentEntry: initialCurrentEntry,
};

export const ticketDetailsReducer = (state = ticketDetailsInitialState, { type, payload }) => {
    switch (type) {
        case ticketDetailsActions.SET_TICKET_DATA: {
            return {
                ...state,
                ticket: payload,
                asset: payload?.resolvedAsset,
            };
        }
        case ticketDetailsActions.UPDATE_TICKET_DATA: {
            return {
                ...state,
                ticket: {
                    ...state.ticket,
                    [payload?.propertyName]: payload?.propertyValue,
                },
            };
        }

        case ticketDetailsActions.SET_CURRENT_TICKET_ENTRY: {
            return {
                ...state,
                currentEntry: payload,
            };
        }
        case ticketDetailsActions.RESET_CURRENT_ENTRY: {
            return {
                ...state,
                currentEntry: initialCurrentEntry,
            };
        }
        case ticketDetailsActions.UPDATE_CURRENT_TICKET_ENTRY: {
            if (payload?.propertyName === 'shiftStart') {
                if (moment(payload?.propertyValue).isBefore(moment(state.currentEntry.shiftEnd))) {
                    return {
                        ...state,
                        currentEntry: {
                            ...state.currentEntry,
                            shiftStart: payload?.propertyValue,
                        },
                    };
                }

                if (moment(payload?.propertyValue).isAfter(moment(state.currentEntry.shiftEnd))) {
                    return {
                        ...state,
                        currentEntry: {
                            ...state.currentEntry,
                            shiftStart: payload?.propertyValue,
                            shiftEnd: moment(payload?.propertyValue).add(1, 'hours'),
                        },
                    };
                }
            }

            if (payload?.propertyName === 'shiftEnd') {
                if (moment(payload?.propertyValue).isAfter(moment(state.currentEntry.shiftStart))) {
                    return {
                        ...state,
                        currentEntry: {
                            ...state.currentEntry,
                            shiftEnd: payload?.propertyValue,
                        },
                    };
                }

                if (moment(payload?.propertyValue).isBefore(moment(state.currentEntry.shiftStart))) {
                    return {
                        ...state,
                        currentEntry: {
                            ...state.currentEntry,
                            shiftStart: moment(payload?.propertyValue).subtract(1, 'hours'),
                            shiftEnd: payload?.propertyValue,
                        },
                    };
                }
            }

            return {
                ...state,
                currentEntry: {
                    ...state.currentEntry,
                    [payload?.propertyName]: payload?.propertyValue,
                },
            };
        }

        case ticketDetailsActions.SET_TICKET_OVERLEY_DATA: {
            return {
                ...state,
                ticketOverleyData: {
                    ...state.ticketOverleyData,
                    ...payload,
                },
            };
        }
        case ticketDetailsActions.SET_ASSET_DATA: {
            return {
                ...state,
                asset: payload,
            };
        }
        case ticketDetailsActions.SET_IS_DATA_SAVED: {
            return {
                ...state,
                isDataSaved: payload,
            };
        }

        case ticketDetailsActions.UPDATE_ASSET_DATA: {
            return {
                ...state,
                ticketOverleyData: {
                    ...state.ticketOverleyData,
                    resolvedAsset: payload,
                },
            };
        }

        case ticketDetailsActions.RESET_TICKET_DETAILS: {
            return {
                ...ticketDetailsInitialState,
            };
        }

        default:
            return state;
    }
};
